<template>
    <div>
        <Header />
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        <div class="container">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-5">
                                <div class="product-detail">
                                    <div class="tab-content" id="v-pills-tabContent">
                                        <div class="tab-pane fade active show" id="product-4" role="tabpanel">
                                            <div class="product-img">
                                                <img src="https://kmcorporate.com/wp-content/uploads/2021/06/KM1110-rev2-210609-DEF-1536x1044.jpg"
                                                    alt="img-4" class="img-fluid mx-auto d-block" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end product img -->
                            </div>
                            <div class="col-xl-7">
                                <div class="mt-4">

                                    <p style="font-size: 16px; color: black; font-weight: 400">


                                        Bench unit 1.5 ton.
                                    </p>
                                    <p style="font-size: 16px; color: black; font-weight: 400">
                                        Model KM1110 is a bench crimping unit with cast iron structure to reduct the
                                        deformation of strains under load during the work. Its particular 45° shape is
                                        considered the most versatile shape for all adjustments and maintenance works to
                                        be carried out on the miniapplicator. As standard feature, this model is
                                        equipped with centesimal adjustment of the lower dead center, resettable
                                        counter, led lamp for working area lighting and two reel holder brackets for
                                        eliminating shifting operations with related time loss during miniapplicator
                                        changeover. The transparent shock-proof safety guard guarantees a full view of
                                        the working area. Its complete opening allows the replacement and setting of
                                        tools in a very easy way and without the removal of any parts. The setting key
                                        to reset the machine is situated in the front side of the unit, this allows the
                                        operator not to move from his working position. </p>
                                </div>
                                <br />
                                <div class="mt-4">

                                    <p style="font-size: 16px; color: black; font-weight: 400">
                                        On request, the machine can be equipped with a crimp force monitoring system.



                                    </p>
                                </div>
                                <br />



                                <p style="font-size: 14px; color: black; font-weight: 400">
                                    All data and measures are indicative and can be changed without notice. Certain
                                    types of extremely hard, tough wires may not be able to be processed even if written
                                    within the indicated ranges. In case of doubt, KM Corporate is willing to produce
                                    samples of your wire.
                                </p>
                            </div>
                        </div>
                        <!-- end row -->
                        <br />
                        <br />
                        <br />
                        <div class="row">
                            <div class="col-5"></div>
                            <div class="col-7">
                                <button @click="file()" class="btn btn-primary" style="width:100%"><i
                                        class="fa fa-download"></i> Download The Technical Sheet</button>
                            </div>
                        </div>
                        <br><br><br>
                        <div class="row">
                            <h1 style="color: black; font-weight: 800; margin-left: 40px" class="font-size-20 mb-3">
                                Gallery

                            </h1>

                        </div>
                        <br />

                        <div class="row" style="margin-left:60px;">

                            <vue-picture-swipe :items="items"></vue-picture-swipe>
                        </div>
                        <!-- end row -->

                    </div>
                </div>
                <!-- end card -->
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
    components: {

        Header,
        Footer,


    },
    data() {
        return {
            items: [{
                src: 'https://kmcorporate.com/wp-content/uploads/2021/06/KM1110-con-miniapplicatore-rev2-210609-DEF-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/06/KM1110-con-miniapplicatore-rev2-210609-DEF-150x150.jpg',
                w: 1200,
                h: 900,
                alt: 'some numbers on a grey background' // optional alt attribute for thumbnail image
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/07/KM01110AM-rev.1-210714-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/KM01110AM-rev.1-210714-150x150.jpg',
                w: 1200,
                h: 900
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/07/KM1110-KM-039-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/KM1110-KM-039-150x150.jpg',
                w: 1200,
                h: 900
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/07/KM1110-KM-037-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/KM1110-KM-037-150x150.jpg',
                w: 1200,
                h: 900
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/07/KM1110-KM-036-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/KM1110-KM-036-150x150.jpg',
                w: 1200,
                h: 900
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/07/KM1110-KM-025-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/KM1110-KM-025-150x150.jpg',
                w: 1200,
                h: 900
            }
            ]
        };

    },

    methods: {
        file() {
            window.location.href = "https://kmcorporate.com/wp-content/uploads/2021/06/Scheda_Km1110_Rev-19-01-Low.pdf";
        }
    }
};
</script>